import store from "../store";

export default async (to, from, next) => {
    if (to.redirectedFrom) {
        if (to.redirectedFrom.fullPath.startsWith('/school/')) {
            if (!store.state.welcomeScreenPassed) {
                await store.dispatch('update', {
                    welcomeScreenPassed: true,
                });
            }
        }
    }

    if (!store.state.welcomeScreenPassed) {
        next('/welcome');
    } else {
        next();
    }

    return 0;
}