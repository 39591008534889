<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import {PushNotifications} from "@capacitor/push-notifications";
import {Device} from "@capacitor/device";
import DeviceService from "./services/DeviceService";
import {Capacitor} from "@capacitor/core";
import store from "./store";

let ds = new DeviceService();

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  mounted() {
    this.sync();
  },
  methods: {
    registerPushNotifications() {
      PushNotifications.requestPermissions().then(res => {
        if (res.receive === 'granted') {
          PushNotifications.register();
        }
      });

      PushNotifications.addListener('registration',
          (token) => {
            this.registerToken(token.value);
          }
      );

      PushNotifications.addListener('registrationError',
          () => {
            this.loading = false;
          }
      );
    },
    async registerToken(token) {
      await this.$store.dispatch('togglePushNotifications');
      const device = await Device.getInfo();
      let client = new DeviceService();

      await client.register(token, device).then(() => {
        console.log(device);
        console.log('Registered!');
      });
    },
    async sync() {
      await ds.ping().then(async (res) => {
        console.log(res);
        await store.dispatch('update', {
          userUuid: res.uuid,
        });

        console.log(store.state);
      });

      await ds.syncFavorites().then(res => {
        console.log('Favorites synced');
      });

      if (Capacitor.isPluginAvailable('PushNotifications')) {
        this.registerPushNotifications();
      }
    },
  },
});
</script>