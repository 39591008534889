import { createStore } from "vuex";
import { Storage } from '@capacitor/storage';

const store = createStore({
    state: {
        welcomeScreenPassed: false,
        token: null,
        user: {},
        useType: null,
        favorites: [],
        team: {},
        userUuid: null,
    },
    mutations: {
        logout(state) {
            state.isAuthenticated = false;
            state.token = null;
            state.user = {};
        },
        update(state, payload) {
            Object.keys(payload).forEach((key) => {
                state[key] = payload[key];
            });
        },
        changeUseType(state, useType) {
            state.useType = useType;
        },
    },
    actions: {
        async init(context) {
            const savedState = await Storage.get({
                key: 'state',
            });

            if (savedState.value) {
                await context.commit('update', JSON.parse(savedState.value));
            }

            return true;
        },
        async save(context) {
            await Storage.set({
                key: 'state',
                value: JSON.stringify(context.state),
            });
        },
        logout(context) {
            context.commit('logout');
        },
        update(context, data) {
            context.commit('update', data);
        },
        changeUseType(context, useType) {
            context.commit('changeUseType', useType);
        }
    },
});

export default store;