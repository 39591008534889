import store from "../store";
import Config from '../config';
import Crypter from '../mixins/Crypter'

class Service {
    async _fetch(url, options, blob) {
        const endpoint = `${Config.baseUrl}${url}`;

        const token = Crypter.methods.decrypt(store.state.token);

        //
        let contentType = 'application/json';

        if (options) {
            contentType = options.contentType || 'application/json';

            if (options.contentType === false) {
                contentType = null;
            }
        }

        const headers = {};

        if (contentType) {
            headers['Content-Type'] = contentType;
        }
        //

        headers['X-Team-Domain'] = Config.teamDomain;

        if (store.state.userUuid) {
            headers['X-User-Uuid'] = store.state.userUuid;
        }

        return await fetch(endpoint, {
            ...options,
            headers: {
                ...headers,
                'Accept': 'application/json',
                'X-LearnKit-Agent': 'Open Dag App',
                'X-LearnKit-App-Id': 'app.opendag.mobile',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(this.handleErrors)
            .then(response => this.handleResponse(response, options ? options.method : null, blob));
    }

    handleResponse(response, method, blob) {
        if (blob) {
            return response.blob();
        }

        return response.json();
    }

    async handleErrors(response) {
        if (response.status === 401) {
            await store.dispatch('logout');
        }

        if (!response.ok) {
            //https://stackoverflow.com/questions/38235715/fetch-reject-promise-and-catch-the-error-if-status-is-not-ok
            const result = await response.json();

            const responseError = {
                type: 'Error',
                message: result.message || 'Something went wrong',
                errors: result.errors || result.error,
            };

            let error = new Error();
            error = {...error, ...responseError};
            throw (error);
        }

        return response;
    }
}

export default Service;