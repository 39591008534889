<script>
import Config from "../config";
const CryptoJS = require('crypto-js');
export default {
  name: "Crypter",
  methods: {
    encrypt(text) {
      const passphrase = Config.cypherPhrase;
      return CryptoJS.AES.encrypt(text, passphrase).toString();
    },
    decrypt(cipher) {
      if (!cipher) {
        return '';
      }
      try {
        const passphrase = Config.cypherPhrase;
        const bytes = CryptoJS.AES.decrypt(cipher, passphrase);
        return bytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        return '';
      }
    }
  },
}
</script>