<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" class="h-16">
        <ion-tab-button tab="tab1" href="/tabs/start">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="house" class="svg-inline--fa fa-house w-5 h-5 mb-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M567.5 229.7C577.6 238.3 578.9 253.4 570.3 263.5C561.7 273.6 546.6 274.9 536.5 266.3L512 245.5V432C512 476.2 476.2 512 432 512H144C99.82 512 64 476.2 64 432V245.5L39.53 266.3C29.42 274.9 14.28 273.6 5.7 263.5C-2.876 253.4-1.634 238.3 8.473 229.7L272.5 5.7C281.4-1.9 294.6-1.9 303.5 5.7L567.5 229.7zM144 464H192V312C192 289.9 209.9 272 232 272H344C366.1 272 384 289.9 384 312V464H432C449.7 464 464 449.7 464 432V204.8L288 55.47L112 204.8V432C112 449.7 126.3 464 144 464zM240 464H336V320H240V464z"></path></svg>
          <ion-label>Start</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="news" href="/tabs/news" class="tab-news">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="square-rss" class="svg-inline--fa fa-square-rss w-5 h-5 mb-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M121 112C107.9 111.2 96.58 121.8 96.02 135C95.47 148.3 105.8 159.4 119 159.1c106.4 4.469 196.6 94.66 201 201C320.6 373.9 331.2 384 343.1 384c.3438 0 .6719 0 1.016-.0313c13.25-.5313 23.53-11.72 22.98-24.97C362.5 228.3 251.7 117.5 121 112zM121.6 208.1c-13.11-.4375-24.66 9.125-25.52 22.38c-.8594 13.22 9.172 24.66 22.39 25.5c54.69 3.562 102.1 50.94 105.6 105.6C224.9 374.3 235.4 384 247.1 384c.5313 0 1.062-.0313 1.594-.0625c13.22-.8438 23.25-12.28 22.39-25.5C266.9 280.6 199.4 213.1 121.6 208.1zM128 320c-8.189 0-16.38 3.121-22.63 9.371c-12.5 12.5-12.5 32.76 0 45.26C111.6 380.9 119.8 384 128 384s16.38-3.121 22.63-9.371c12.5-12.5 12.5-32.76 0-45.26C144.4 323.1 136.2 320 128 320zM384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM400 416c0 8.82-7.178 16-16 16H64c-8.822 0-16-7.18-16-16V96c0-8.82 7.178-16 16-16h320c8.822 0 16 7.18 16 16V416z"></path></svg>
          <ion-label>Nieuws</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="account" href="/tabs/favorites" class="tab-favorites">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" class="svg-inline--fa fa-star w-5 h-5 mb-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.5 171.5l-146.4-21.29l-65.43-132.4C310.9 5.971 299.4-.002 287.1 0C276.6 0 265.1 5.899 259.3 17.8L193.8 150.2L47.47 171.5C21.2 175.3 10.68 207.6 29.72 226.1l105.9 102.1L110.6 474.6C107 495.3 123.6 512 142.2 512c4.932 0 10.01-1.172 14.88-3.75L288 439.6l130.9 68.7c4.865 2.553 9.926 3.713 14.85 3.713c18.61 0 35.21-16.61 31.65-37.41l-25.05-145.5l105.9-102.1C565.3 207.6 554.8 175.3 528.5 171.5zM390.2 320.6l22.4 130.1l-117.2-61.48c-4.655-2.442-10.21-2.442-14.87 .0001L163.4 450.7l22.4-130.1C186.7 315.4 184.1 310.1 181.2 306.4l-94.7-92.09l130.9-19.04C222.6 194.5 227.1 191.2 229.4 186.5L288 67.99l58.59 118.5c2.331 4.717 6.833 7.986 12.04 8.744l130.9 19.04l-94.7 92.09C391 310.1 389.3 315.4 390.2 320.6z"></path></svg>
          <ion-label>Favorieten</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/tabs/more" class="tab-more">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="bolt" class="svg-inline--fa fa-bolt w-5 h-5 mb-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M381.1 244.8c-4.687-12.48-16.62-20.75-29.97-20.75l-111.5 .0076l76.88-179.4c5.906-13.73 1.5-29.72-10.59-38.5c-12.06-8.781-28.62-8.091-39.87 1.799L10.95 231.9c-10.03 8.781-13.59 22.86-8.906 35.33C6.728 279.7 18.66 288 32.01 288L143.5 288l-76.88 179.4c-5.906 13.73-1.5 29.72 10.59 38.5C82.82 509.1 89.41 512 96 512c7.531 0 15.06-2.656 21.06-7.922l255.1-223.1C383.1 271.3 386.6 257.2 381.1 244.8zM203.1 148.8l-32.22 75.22H117.2L203.1 148.8zM180.9 363.2l32.22-75.22h53.75L180.9 363.2z"></path></svg>
          <ion-label>Meer</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet } from '@ionic/vue';
import { ellipse, square, triangle } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, IonRouterOutlet },
  computed: {
    useType() {
      return this.$store.state.useType || 'student';
    },
  },
  setup() {
    return {
      ellipse, 
      square, 
      triangle,
    }
  }
}
</script>

<style>
.tab-news.tab-selected {
  @apply text-red-500;
}

.tab-favorites.tab-selected {
  @apply text-blue-500;
}

.tab-more.tab-selected {
  @apply text-green-500;
}
</style>