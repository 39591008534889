const Config = {

    name: process.env.VUE_APP_NAME,

    baseUrl: process.env.VUE_APP_BASE_URL,

    cypherPhrase: process.env.VUE_APP_CYPHER,

    teamDomain: process.env.VUE_APP_TEAM_DOMAIN,

};

export default Config;