import { createRouter, createWebHistory } from '@ionic/vue-router';
import Tabs from '../views/Tabs.vue'
import welcome from "./welcome";

const routes = [
  {
    path: '/',
    redirect: '/tabs/start'
  },
  {
    path: '/welcome',
    component: () => import('../views/Welcome.vue')
  },
  {
    path: '/tabs/',
    component: Tabs,
    beforeEnter: welcome,
    children: [
      {
        path: '',
        redirect: '/tabs/start'
      },
      {
        path: 'start',
        component: () => import('../views/Start.vue')
      },
      {
        path: 'announcement',
        component: () => import('../views/More/Announcement.vue')
      },
      {
        path: 'news',
        component: () => import('../views/News.vue')
      },
      {
        path: 'news/:id',
        component: () => import('../views/NewsItem.vue')
      },
      {
        path: 'news/videos',
        component: () => import('../views/More/TeamVideos.vue')
      },
      {
        path: 'favorites',
        component: () => import('../views/More/Favorites.vue')
      },
      {
        path: 'schools',
        component: () => import('../views/Organisations/Index.vue')
      },
      {
        path: 'activities',
        component: () => import('../views/More/Activities.vue')
      },
      {
        path: 'more',
        component: () => import('../views/More.vue'),
      },
      {
        path: 'more/map',
        component: () => import('../views/More/Map.vue')
      },
      {
        path: 'more/map/:id',
        component: () => import('../views/More/Video.vue')
      },
      {
        path: 'favorites',
        component: () => import('../views/Favorites.vue')
      },
      {
        path: 'more/favorites',
        component: () => import('../views/More/Favorites.vue')
      },
      {
        path: 'more/book',
        component: () => import('../views/More/Book.vue')
      },
      {
        path: 'more/filter',
        component: () => import('../views/More/Filter.vue')
      },
      {
        path: 'more/info',
        component: () => import('../views/More/About.vue')
      },
      {
        path: 'compare',
        component: () => import('../views/More/Compare.vue')
      },
      {
        path: 'more/activities',
        component: () => import('../views/More/Activities.vue')
      },
      {
        path: 'more/activities/:id',
        component: () => import('../views/More/Activity.vue')
      },
      {
        path: 'more/bovo',
        component: () => import('../views/More/Bovo.vue')
      },
      {
        path: '/tabs/start/organisations/:id',
        component: () => import('../views/Organisations/Show')
      },
      {
        path: '/tabs/start/organisations/:id/activities/:specialMode/:category?',
        component: () => import('../views/Organisations/Activities/Category')
      },
      {
        path: '/school/:slug',
        redirect: to => {
          return { path: `/tabs/start/organisations/${to.params.slug}` };
        },
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
