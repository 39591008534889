import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';

/* Theme variables */
import './theme/variables.css';

import './theme/tailwind.css';

import store from "./store";
import VueEasyLightbox from "vue-easy-lightbox";
import YouTube from "vue3-youtube";
import TeamService from "./services/TeamService";
import DeviceService from "./services/DeviceService";

let client = new TeamService();

client.active().then(res => {
  store.dispatch('init').then(async () => {
    await store.dispatch('update', {
      team: res,
    });

    const app = createApp(App)
        .use(IonicVue)
        .use(store)
        .use(VueEasyLightbox)
        .component('YouTube', YouTube)
        .use(router);

    store.subscribe(() => {
      console.log('State updated');
      store.dispatch('save');
    });

    router.isReady().then(() => {
      app.mount('#app');
    });
  });
});

let iosMeta = document.createElement('meta');
iosMeta.name = 'apple-itunes-app';
iosMeta.content = 'app-id=1545952078';

document.head.appendChild(iosMeta);
