import Service from "./Service";
import store from "../store";

class DeviceService extends Service {
    async register(token, device) {
        return await this._fetch(`/devices/register`, {
            method: 'POST',
            body: JSON.stringify({
                fcm_token: token,
                device: device,
            }),
        });
    }

    async ping() {
        return await this._fetch(`/devices/ping`, {
            method: 'POST',
        });
    }

    async syncFavorites() {
        return await this._fetch(`/devices/favorites`, {
            method: 'POST',
            body: JSON.stringify({
                favorites: store.state.favorites,
            }),
        });
    }
}

export default DeviceService;